<script lang="ts" setup>
import { ref, watch } from "vue";
import { useElementVisibility } from "@vueuse/core";
import { useRouter } from "vue-router";
import VButton from "@magnit/core/src/components/VButton/VButton.vue";
import VText from "@magnit/core/src/components/VText/VText.vue";
import VHidden from "@magnit/core/src/components/VHidden/VHidden.vue";
import useAnalytics from "~/composables/useAnalytics";
import { Routes } from "~/utils/routes";

const cardsRef = ref<HTMLElement | null>(null);
const cardsRefIsVisible = useElementVisibility(cardsRef);

const router = useRouter();
const { send } = useAnalytics();

watch(cardsRefIsVisible, (newCardsRefIsVisible) => {
  if (newCardsRefIsVisible) {
    send("LandingPage:Online:Cards:View");
  }
});

const onLoginButtonClick = () => {
  send("LandingPage:Online:Cards:Auth:Click");
  router.push(Routes.Login);
};
</script>

<template>
  <section id="online" class="online">
    <div class="online-cards-wrap">
      <div class="online-wrap">
        <VText
          tag="h3"
          :font="{
            xs: 'headline-small',
            ml: 'headline-large',
            l: 'display-medium',
          }"
          class="online-wrap__title"
        >
          В Магнит Онлайн есть всё главное для выгодных покупок:
        </VText>
        <div ref="cardsRef" class="online__cards">
          <div class="online__cards__item online__cards__item-card">
            <div class="online__cards__item-card__content">
              <img
                class="online__cards__item__img"
                src="~/assets/illustrations/landing/sections/second/1.webp"
                alt="Карта Магнит Плюс"
              >
              <div class="online__cards__item__text">
                <VText :font="{ xs: 'title-small', xl: 'headline-small' }">
                  До 2% бонусами за покупки
                </VText>
                <VHidden :hide="{ xs: true, xl: false }">
                  <VButton
                    class="online__cards__item-card__btn-desktop"
                    theme="invert"
                    @click="onLoginButtonClick"
                  >
                    Активировать карту
                  </VButton>
                </VHidden>
              </div>
              <VHidden :hide="{ xs: false, xl: true }">
                <div class="online__cards__item-card__btn-mobile">
                  <VButton theme="invert" full-width size="m" @click="onLoginButtonClick">
                    Активировать карту
                  </VButton>
                </div>
              </VHidden>
            </div>
          </div>
          <div class="online__cards__item online__cards__item-phone">
            <img
              class="online__cards__item__img online__cards__item__img--phone"
              src="~/assets/illustrations/landing/sections/second/2.webp"
              alt="Баланс бонусный накоплений"
            >
            <div class="online__cards__item__text">
              <VText :font="{ xs: 'title-small', xl: 'headline-small' }">
                Карта и баланс начислений под рукой
              </VText>
            </div>
          </div>
          <div class="online__cards__item online__cards__item-percents">
            <img
              class="online__cards__item__img online__cards__item__img--percents"
              src="~/assets/illustrations/landing/sections/second/3.webp"
              alt="Акции и скидки"
            >
            <span class="online__cards__item__soon">3 категории каждый месяц</span>
            <div class="online__cards__item__text">
              <VText :font="{ xs: 'title-small', xl: 'headline-small' }">
                От 10% бонусами за&nbsp;любимые категории
              </VText>
            </div>
          </div>
          <div class="online__cards__item online__cards__item-coupons">
            <img
              class="online__cards__item__img"
              src="~/assets/illustrations/landing/sections/second/4.webp"
              alt="Акции и скидки"
            >
            <div class="online__cards__item__text">
              <VText :font="{ xs: 'title-small', xl: 'headline-small' }">
                Купоны и персональные предложения
              </VText>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="postcss">
.online {
  font-family: var(--pl-font-family);
}

.online-cards-wrap {
  padding: 32px 20px;
}

.online-wrap {
  margin: 0 auto;
  max-width: 1200px;
}

.online__cards {
  padding: 76px 0 0;
  z-index: var(--pl-z-index-level2);
  position: relative;
  display: grid;
  gap: 0;
  row-gap: 44px;

  &__item {
    display: flex;
    box-sizing: border-box;
    align-items: flex-end;
    justify-content: space-between;
    background-color: #fff3da;
    border-radius: 18px;
    box-shadow: 8px 8px 22px 0 #8693c433;
    transition: box-shadow 0.16s ease-in-out;
    height: 140px;
    position: relative;
    width: 100%;

    &__img {
      display: block;
      max-width: 180px;
      width: 100%;
    }

    &__text {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding: 0 20px;
    }

    &__soon {
      align-items: center;
      border-radius: 18px;
      display: flex;
      font-size: 14px;
      font-weight: 400;
      height: 28px;
      justify-content: center;
      line-height: 20px;
      position: absolute;
      bottom: 16px;
      width: 260px;
      right: 0;
      left: 0;
      margin: 0 24px 0 auto;
      background: linear-gradient(-45deg, #ffca79 0%, #ff3d39 63.26%, #ff266d 100%);
      color: white;
    }

    &-card {
      height: 203px;
      flex-direction: column;

      &__content {
        margin-top: -58px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        width: 100%;
        height: 100%;
      }

      &__btn-mobile {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 16px;
      }

      &__btn-desktop {
        margin-top: 17px;
      }

      .online__cards__item__text {
        padding-top: 56px;
      }
    }

    &-phone {
      img {
        margin-left: -40px;
        max-width: 220px;
      }
    }

    &-percents {
      height: 203px;

      .online__cards__item__text {
        margin-bottom: 22px;
      }

      img {
        margin-left: -25px;
        margin-top: -25px;
        max-width: 200px;
        align-self: flex-start;
      }
    }

    &-coupons {
      height: 160px;

      img {
        align-self: flex-start;
        margin-top: -20px;
        margin-left: -30px;
        max-width: 200px;
      }
    }
  }
}

@media (--pl-viewport-from-m) {
  .online__cards {
    padding-top: 94px;

    &__item {
      height: 160px;

      &-card {
        height: 203px;
      }

      &-phone {
        height: 140px;
      }

      &__soon {
        margin-right: 12px;
      }
    }
  }
}

@media (--pl-viewport-from-ml) {
  .online-cards-wrap {
    padding: 64px 32px;
  }

  .online-wrap__title {
    width: 677px;
  }

  .online__cards {
    padding-top: 77px;
    column-gap: 32px;
    grid-template-columns: repeat(2, 1fr);

    &__item {
      &__text {
        padding-right: 20px;
      }

      &-percents {
        order: 2;
        height: 203px;
      }

      &-phone {
        order: 3;
        height: 140px;
      }

      &-coupons {
        order: 4;
        height: 140px;
      }
    }
  }
}

@media (--pl-viewport-from-l) {
  .online-wrap {
    &__title {
      width: 740px;
    }
  }

  .online__cards {
    &__item {
      &__text {
        padding-right: 32px;
      }

      &-percents {
        img {
          margin-left: -35px;
          max-width: 210px;
        }
      }

      &__soon {
        margin-right: 26px;
      }
    }
  }
}

@media (--pl-viewport-from-xl) {
  .online__cards {
    column-gap: 64px;

    &__item {
      box-shadow: none;
      height: 240px;

      &:hover {
        box-shadow: 8px 8px 22px 0 #8693c433;
      }

      &__img {
        max-width: 278px;
      }

      &__text {
        padding-left: 28px;
        padding-right: 40px;
      }

      &-percents {
        img {
          max-width: 333px;
          margin-left: -55px;
          margin-top: -45px;
        }

        .online__cards__item__text {
          margin-bottom: 0;
        }
      }

      &-phone {
        img {
          margin-left: -90px;
          max-width: 370px;
        }
      }

      &-card {
        &__content {
          margin-top: 0;
        }

        .online__cards__item__text {
          padding-top: 0;
        }
      }

      &-coupons {
        img {
          max-width: 300px;
          margin-top: -30px;
        }
      }

      &__soon {
        top: 16px;
        margin-right: 16px;
        bottom: initial;
      }
    }
  }
}
</style>
